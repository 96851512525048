<template>
  <v-navigation-drawer
    id="dashboardDrawer"
    class="hidden-sm-and-down"
    app
    right
    floating
    clipped
  >
    <!-- Premium Pass Sheet -->
    <template v-if="isUserSubscribed">
      <v-card
        to="/premium-pass"
        class="pa-4 card-gradient white--text d-flex flex-column justify-start"
        height="56vh"
        rounded="0"
        elevation="0"
      >
        <div class="text-h6 d-flex align-center justify-center">
          <v-icon class="ml-2" color="amber darken-1" size="70">
            mdi-crown
          </v-icon>
        </div>

        <div class="mt-4 text-h6 text-center">
          Congratulations!<br />
          You have the Premium Pass
        </div>

        <div class="mt-4 mx-auto text-center">
          <div class="mb-2"><i>Pass valid till:</i></div>

          <v-alert v-if="isUserSubscribed" color="white" max-width="250" dense>
            {{ subscribedTillDateText() }}
          </v-alert>
        </div>

        <div class="mt-2">
          <v-img
            height="110"
            class="mx-auto"
            src="../../../assets/home/dashboard/undraw_Celebration_re_kc9k.svg"
            contain
          ></v-img>
        </div>
      </v-card>
    </template>

    <!-- Premium Pass Ad -->
    <template v-else>
      <v-card
        to="/premium-pass"
        class="pa-4 card-gradient white--text d-flex flex-column justify-start"
        height="56vh"
        rounded="0"
        elevation="0"
      >
        <div class="text-h6 d-flex align-center">
          Premium Pass
          <v-icon class="ml-2" color="amber darken-1" size="25"
            >mdi-crown</v-icon
          >
        </div>

        <div class="mt-4">
          <ul>
            <li>Top Quality Mock Tests</li>
            <li>Detailed Solutions</li>
            <li>Bilingual Hin/Eng</li>
            <li>And more...</li>
          </ul>
        </div>

        <div class="mt-4 mx-auto text-center">
          <div class="mb-2"><i>Starting at only Rs. 99/month</i></div>
          <v-btn to="/premium-pass" color="white" elevation="0">
            Unlock Now
          </v-btn>
        </div>

        <div class="mt-7 mb-n7">
          <v-img
            height="150"
            class="mx-auto"
            src="../../../assets/home/dashboard/undraw_mathematics_4otb.svg"
            contain
          ></v-img>
        </div>
      </v-card>
    </template>

    <!-- Quotes Sheet -->
    <v-sheet
      class="px-4 pt-12 d-flex flex-column justify-center align-center"
      color="white"
      height="33.4vh"
    >
      <div class="text-center text-overline">
        <i>
          "
          {{ quotes[selectedQuoteIndex] }}
          "
        </i>
      </div>
    </v-sheet>
  </v-navigation-drawer>
</template>
  
<script>
var dayjs = require("dayjs");

export default {
  name: "DashboardDrawer",
  props: ["superLoading", "isUserSubscribed", "userData"],

  data: () => ({
    loading: true,
    selectedQuoteIndex: 0,
    quotes: [
      "Life is short. Live it. Fear is natural. Face it. Memory is powerful. Use it.",
      "If we wait until we’re ready, we’ll be waiting for the rest of our lives.",
      "It’s never too late to be what you might have been.",
      "You don’t have to be great to start. But you have to start to be great.",
      "Many of life’s failures are people who did not realise how close they were to success when they gave up.",
      "A river cuts through rock not because of its power but because of its persistence.",
      "Don’t stop until you’re proud.",
      "There are no secrets to success. It is the result of preparation, hard work, and learning from failure.",
      "Striving for success without hard work is like trying to harvest where you haven’t planted.",
      "Failure is the opportunity to begin again more intelligently.",
      "Success consists of going from failure to failure without loss of enthusiasm.",
    ],
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    selectRandomQuote() {
      const quotes = this.quotes;
      const randomIndex = Math.floor(Math.random() * quotes.length);

      this.selectedQuoteIndex = randomIndex;
    },

    subscribedTillDateText() {
      if (!this.isUserSubscribed) {
        return;
      }
      return dayjs(this.userData.subscribedTill.seconds * 1000).format(
        "D MMMM, YYYY"
      );
    },
  },
  mounted() {
    this.loading = false;
    this.selectRandomQuote();
  },
};
</script>

<style scoped>
.card-gradient {
  background: #8e54e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #8e54e9,
    #fff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #8e54e9,
    #fff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>