<template>
  <v-row justify="center" id="highlightedTests">
    <!-- Heading -->
    <v-col cols="12" sm="12" class="d-flex justify-space-between">
      <h1 class="text-subtitle-2 text-left d-flex align-center">
        <v-icon class="mr-2" color="blue" small>mdi-star-circle</v-icon>
        Live Tests
      </h1>
      <v-btn to="/live-tests" text small
        >View all <v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </v-col>

    <!-- if Loading -->
    <v-row v-if="loading || superLoading" justify="center" class="mx-md-5">
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4" class="hidden-sm-and-down">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- if Error -->
    <v-row v-else-if="error" justify="center" class="my-3">
      <v-col cols="12" md="9">
        <v-alert type="warning" dense text>{{ error }}</v-alert>
      </v-col>
    </v-row>

    <!-- Else Data -->
    <v-col v-else cols="12" sm="12" class="pa-0">
      <!-- Empty State -->
      <v-alert v-if="!(highlightedTests.length > 0)" type="info" dense text>
        Stay tuned and be prepared for upcoming live tests.
      </v-alert>

      <!-- Slide with Tests -->
      <v-slide-group v-else>
        <v-slide-item v-for="test in highlightedTests" :key="test.id">
          <!-- Test Card -->
          <v-card
            id="card-gradient"
            class="ma-3 pb-1 rounded-lg blue"
            :max-width="$vuetify.breakpoint.mobile ? 260 : '25.7vw'"
          >
            <v-list-item>
              <v-list-item-content>
                <!-- isBilingual / free or premium -->
                <div class="text-overline mb-4">
                  <!-- Free Chip -->
                  <v-chip
                    v-if="!test.isPremium"
                    color="green accent-3 white--text"
                    class="mr-2"
                    x-small
                  >
                    Free
                  </v-chip>

                  <v-icon
                    v-else
                    color="amber darken-2"
                    class="mr-2 white rounded-circle pa-1"
                    size="20"
                  >
                    mdi-crown-outline
                  </v-icon>

                  <!-- Language Chip -->
                  <v-chip class="white" x-small>
                    <span v-if="test.isBilingual"> Eng/Hin </span>
                    <span v-else-if="test.onlySecondaryLang"> Hindi </span>
                    <span v-else> English </span>
                  </v-chip>
                </div>

                <!-- Test name and time -->
                <v-list-item-title class="text-h5 white--text pl-2">
                  {{ test.testName }}
                </v-list-item-title>

                <v-list-item-subtitle class="mt-2 white--text">
                  <!-- Start Time -->
                  <div class="mt-1">
                    <v-icon class="mr-1" small>mdi-clock</v-icon>
                    <b>Start: </b>
                    {{ dateTimeText(test.startDateTime.seconds * 1000) }}
                  </div>

                  <!-- End Time -->
                  <div class="my-2">
                    <v-icon class="mr-1" small>mdi-clock-alert</v-icon>
                    <b class="mr-2">End: </b>
                    {{ dateTimeText(test.endDateTime.seconds * 1000) }}
                  </div>

                  <!-- Questions and Duration -->
                  <div>
                    <v-icon class="mr-1" small>mdi-card-text</v-icon>
                    <b>{{ test.questionCount }}</b> Questions

                    <v-icon class="mx-1" small>mdi-timer-sand</v-icon>
                    <b>{{ test.testDuration }}</b>
                    Mins
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- SVG Image (w/ premium effect) -->
              <v-list-item-avatar
                v-if="!$vuetify.breakpoint.mobile"
                tile
                size="110"
              >
                <v-img
                  v-if="test.isPremium"
                  class="mx-auto hidden-sm-and-downs"
                  src="../../../assets/home/dashboard/gold_undraw_Choose_re_7d5a.svg"
                  contain
                ></v-img>
                <v-img
                  v-else
                  class="mx-auto hidden-sm-and-downs"
                  src="../../../assets/home/dashboard/undraw_Choose_re_7d5a.svg"
                  contain
                ></v-img>
              </v-list-item-avatar>
            </v-list-item>

            <!-- Button (Unlock/Take Test) -->
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <!-- Re-attempt and Result Button -->
              <template v-if="test.id in results">
                <v-btn
                  :color="
                    test.isPremium ? 'amber--text text--darken-4 px-3' : 'px-3'
                  "
                  elevation="1"
                  :to="
                    test.isPremium && !isUserSubscribed
                      ? '/premium-pass'
                      : {
                          name: 'Result',
                          params: { id: test.id },
                        }
                  "
                  rounded
                  small
                >
                  <template v-if="test.isPremium">
                    <span v-if="isUserSubscribed"> Re-Attempt / Solution </span>
                    <span v-else class="d-flex align-center">
                      <v-icon class="mr-2" size="17">mdi-lock</v-icon>Unlock
                    </span>
                  </template>
                  <template v-else> Result / Solution </template>
                </v-btn>
              </template>

              <!-- Attempt Button -->
              <v-btn
                v-else
                :color="
                  test.isPremium ? 'amber--text text--darken-4 px-3' : 'px-3'
                "
                elevation="1"
                :to="
                  test.isPremium && !isUserSubscribed
                    ? '/premium-pass'
                    : {
                        name: 'Test',
                        params: { id: test.id, testObj: test },
                      }
                "
                rounded
                small
              >
                <template v-if="test.isPremium">
                  <span v-if="isUserSubscribed"> Attempt </span>
                  <span v-else class="d-flex align-center">
                    <v-icon class="mr-2" size="17">mdi-lock</v-icon>Unlock
                  </span>
                </template>
                <template v-else> Attempt </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-col>
  </v-row>
</template>

<script>
const dayjs = require("dayjs");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrBefore);

export default {
  name: "HighlightedTests",
  props: ["superLoading", "isUserSubscribed"],

  computed: {
    highlightedTests() {
      var highlightedTests = [];

      this.tests.forEach((testObj) => {
        // curr dateTime <= endDateTime
        if (dayjs().isSameOrBefore(dayjs(testObj.endDateTime.seconds * 1000))) {
          highlightedTests.push(testObj);
        }
      });

      return highlightedTests;
    },
  },

  data: () => ({
    loading: true,
    error: "",
    tests: [],
    results: {},
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    dateTimeText(timestamp) {
      return dayjs(timestamp).format("hh:mm a, D MMM");
    },

    fetchHighlightedTests() {
      // get courses array from store
      const highlightedTestsArr = this.$store.getters.highlightedTests;
      const resultsObj = this.$store.getters.results;

      //if not found in store
      if (
        highlightedTestsArr.length <= 0 ||
        typeof highlightedTestsArr == "undefined"
      ) {
        //get folder names from server
        this.$store
          .dispatch("getHighlightedTests")
          .then((res) => {
            if (res) {
              this.tests = res.testResArr;
              this.results = res.resultsResObj;
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.tests = highlightedTestsArr;
        this.results = resultsObj;
        this.setLoading(false);
      }
    },
  },

  mounted() {
    this.fetchHighlightedTests();
  },
};
</script>

<style>
#highlightedTests #card-gradient {
  background: #fc5c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #6a82fb,
    #fc5c7d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #879af8,
    #f78fa4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
