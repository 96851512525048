<template>
  <v-row justify="center" id="allCourseTestGroups">
    <!-- Heading -->
    <v-col cols="12" sm="12" class="d-flex justify-space-between">
      <h1 class="text-subtitle-2 text-left d-flex align-center">
        <v-icon class="mr-2" color="green" small
          >mdi-book-variant-multiple</v-icon
        >
        All courses and Test Groups
      </h1>
    </v-col>

    <!-- if Loading -->
    <v-row v-if="loading || superLoading" justify="center" class="mx-md-5">
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4" class="hidden-sm-and-down">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- if Error -->
    <v-row v-else-if="error" justify="center" class="my-3">
      <v-col cols="12" md="9">
        <v-alert type="warning" dense text>{{ error }}</v-alert>
      </v-col>
    </v-row>

    <!-- Else Data -->
    <v-col v-else cols="12" sm="12" class="px-0 px-md-4">
      <!-- Empty State -->
      <v-alert v-if="!(courses.length > 0)" type="info" dense text>
        No Courses added :(
      </v-alert>

      <!-- Empty State 2 -->
      <v-alert
        v-else-if="!(Object.keys(testGroupCounter).length > 0)"
        type="info"
        dense
        text
      >
        No Test Groups added :(
      </v-alert>

      <!-- Slide with Tests -->
      <div v-else>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="blue"
          :vertical="!landingPage && !$vuetify.breakpoint.mobile"
          :show-arrows="true"
        >
          <template v-for="course in courses">
            <v-tab :key="course.courseName" class="d-flex justify-start">
              {{ course.courseName }}
            </v-tab>
          </template>

          <v-tabs-items
            :class="
              landingPage || $vuetify.breakpoint.mobile ? 'pa-0 pa-md-3' : ''
            "
            v-model="tab"
          >
            <!-- Courses Loop -->
            <template v-for="(course, courseIndex) in courses">
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
                :key="course.courseName + '_tab-item'"
              >
                <v-container>
                  <v-row align="center" justify="start">
                    <!-- Test Group Loop -->
                    <template v-for="(testGroup, i) in course.testGroups">
                      <v-col
                        cols="12"
                        :sm="landingPage || $vuetify.breakpoint.mobile ? 4 : 6"
                        class=""
                        :key="testGroup + '_' + testGroup"
                      >
                        <!-- Card -->
                        <v-card class="mx-auto card-gradient">
                          <v-card-text class="white--text">
                            <div>
                              <small> <i>Test Group :</i> </small>
                            </div>

                            <h1 class="text-h5 ml-1">
                              {{ testGroup }}
                            </h1>

                            <v-divider class="my-2 grey lighten-2"></v-divider>

                            <!-- Course Name -->
                            <h1 class="text-body-2 mt-4">
                              <v-chip class="white--text" outlined>
                                {{ course.courseName }}
                              </v-chip>
                            </h1>

                            <!-- Tests Count -->
                            <div
                              v-if="
                                course.courseName in testGroupCounter &&
                                testGroup in testGroupCounter[course.courseName]
                              "
                              class="mt-2"
                            >
                              <v-chip class="white--text" outlined>
                                Tests Available:
                                {{
                                  testGroupCounter[course.courseName][testGroup]
                                }}
                              </v-chip>
                            </div>

                            <!-- Tests Coming Soon Text -->
                            <div class="mt-2" v-else>
                              <v-chip class="white--text" outlined>
                                Tests Coming Soon :)
                              </v-chip>
                            </div>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- View Tests Button -->
                            <v-btn
                              :disabled="
                                !(
                                  course.courseName in testGroupCounter &&
                                  testGroup in
                                    testGroupCounter[course.courseName] &&
                                  testGroupCounter[course.courseName][
                                    testGroup
                                  ] > 0
                                )
                              "
                              :to="`/course-tests/${courseIndex}/testGroup/${i}`"
                              color="white"
                              small
                              rounded
                            >
                              View Tests
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-tabs>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AllCourseTestGroups",
  props: ["superLoading", "landingPage"],

  data: () => ({
    loading: true,
    error: "",
    tab: null,
    courses: [],
    testGroupCounter: {},
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    fetchCourses() {
      // get courses array from store
      const coursesArr = this.$store.getters.courses;

      //if not found in store
      if (coursesArr.length <= 0 || typeof coursesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getMetaDoc")
          .then((res) => {
            if (res) {
              this.courses = res.courses;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.courses = coursesArr;
        this.setLoading(false);
      }
    },

    fetchTestGroupCounter() {
      this.setLoading(true);
      // get courses array from store
      const testGroupCounter = this.$store.getters.testGroupCounter;

      //if not found in store
      if (
        Object.keys(testGroupCounter).length <= 0 ||
        typeof testGroupCounter == "undefined"
      ) {
        //get folder names from server
        this.$store
          .dispatch("getTestGroupCounter")
          .then((res) => {
            if (res) {
              this.testGroupCounter = res;
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.testGroupCounter = testGroupCounter;
        this.setLoading(false);
      }
    },
  },

  mounted() {
    this.fetchCourses();
    this.fetchTestGroupCounter();
  },
};
</script>

<style scoped>
.theme--light.v-tabs-items {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-radius: 1.5%;
}

.card-gradient {
  background: #fc5c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #4e54c8,
    #8f94fb
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top left,
    #4e54c8,
    #8f94fb
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>