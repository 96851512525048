<template>
  <div id="dashboard" class="indigo lighten-5 py-md-4">
    <!-- Check if the profile is complete -->
    <v-container v-if="!userData.selectedCourse" class="mt-12">
      <ProfileAlertRow />
    </v-container>

    <div v-else>
      <!-- Right hand side drawer (hidden on mobile) -->
      <DashboardDrawer
        :isUserSubscribed="isUserSubscribed"
        :userData="userData"
        :superLoading="loading"
        @setSuperLoading="setLoading"
      />

      <div class="mt-6"></div>

      <!-- Name, Sub Status and Search Courses -->
      <SubStatusAndSearch
        :superLoading="loading"
        :isUserSubscribed="isUserSubscribed"
        :userData="userData"
        @setSuperLoading="setLoading"
      />

      <v-spacer class="my-9"></v-spacer>

      <!-- Live/Highlighted Tests -->
      <div class="mb-16 px-6 pb-6 white">
        <!-- Top SVG -->
        <div class="custom-shape-divider-bottom-1630402165">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <HighlightedTests
          :superLoading="loading"
          :isUserSubscribed="isUserSubscribed"
          @setSuperLoading="setLoading"
        />

        <v-spacer class="py-2"></v-spacer>

        <!-- Bottom SVG -->
        <div class="custom-shape-divider-top-1630398810 mt-6">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      <v-spacer class="py-6"></v-spacer>

      <!-- Tests for you -->
      <div class="mb-16 px-4">
        <SelectedCourseTests
          :superLoading="loading"
          :publicPage="false"
          :isUserSubscribed="isUserSubscribed"
          :selectedCourse="userData.selectedCourse"
          @setSuperLoading="setLoading"
        />
      </div>

      <!-- All Course - Test Groups -->
      <div class="mb-16 px-4">
        <AllCourseTestGroups
          :superLoading="loading"
          :landingPage="false"
          @setSuperLoading="setLoading"
        />
      </div>

      <div class="mb-16 px-4">
        <Courses :landingPage="false" />
      </div>
    </div>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

import ProfileAlertRow from "@/components/Home/ProfileAlertRow.vue";
import DashboardDrawer from "@/components/Home/Dashboard/DashboardDrawer.vue";
import SubStatusAndSearch from "@/components/Home/Dashboard/SubStatusAndSearch.vue";
import HighlightedTests from "@/components/Home/Dashboard/HighlightedTests.vue";
import SelectedCourseTests from "@/components/Home/Dashboard/SelectedCourseTests.vue";
import AllCourseTestGroups from "@/components/Home/Dashboard/AllCourseTestGroups.vue";
import Courses from "@/components/Landing/Courses.vue";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Student Dashboard",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Student Dashboard Page description lorem ipsum dolor sit amet.",
      },
    ],
  },

  components: {
    ProfileAlertRow,
    DashboardDrawer,
    SubStatusAndSearch,
    HighlightedTests,
    SelectedCourseTests,
    AllCourseTestGroups,
    Courses,
  },

  computed: {
    userData() {
      return this.$store.getters.userData;
    },

    firstName() {
      return this.userData.fullName.split(" ")[0];
    },

    isUserSubscribed() {
      const userDataObj = this.userData;
      if (userDataObj && "subscribedTill" in userDataObj) {
        const userSubscribedTill = dayjs(
          userDataObj.subscribedTill.seconds * 1000
        );
        return dayjs().isSameOrBefore(userSubscribedTill);
      }
      return false;
    },
  },

  data: () => ({
    loading: true,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
    },
  },

  mounted() {
    this.loading = false;
  },
};
</script>

<style scoped>
/* Top Wave CSS */
.custom-shape-divider-bottom-1630402165 {
  position: absolute;
  margin-top: -1.7rem;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1630402165 svg {
  position: relative;
  display: block;
  width: calc(187% + 1.3px);
  height: 31px;
}

.custom-shape-divider-bottom-1630402165 .shape-fill {
  fill: #fff;
}

/* Bottom Wave CSS */
.custom-shape-divider-top-1630398810 {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1630398810 svg {
  position: relative;
  display: block;
  width: calc(141% + 1.3px);
  height: 73px;
}

.custom-shape-divider-top-1630398810 .shape-fill {
  fill: #fff;
}
</style>