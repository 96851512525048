<template>
  <v-row justify="center" id="subStatusAndSearch" class="mx-0 px-0">
    <!-- if Loading -->
    <v-row v-if="loading || superLoading" justify="space-between">
      <v-col sm="5">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="avatar, sentences"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- if Error -->
    <v-row v-else-if="error" justify="center" class="my-3">
      <v-col cols="12" md="9">
        <v-alert type="warning" dense text>{{ error }}</v-alert>
      </v-col>
    </v-row>

    <!-- Else Data -->
    <v-row v-else justify="space-between" align="center" class="px-0 mx-0">
      <!-- Name and Sub Status -->
      <v-col cols="12" md="5">
        <!-- User Name and Sub Status/Unlock all tests button -->
        <v-list-item class="mt-md-n6" two-line>
          <v-list-item-avatar>
            <img :src="userData.photoURL" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              {{ userData.fullName }}
              <v-icon
                v-if="isUserSubscribed"
                class="ml-1"
                color="amber darken-2"
                >mdi-crown-outline</v-icon
              >
            </v-list-item-title>
            <v-list-item-subtitle class="mt-1">
              <span>
                <v-btn
                  to="/premium-pass"
                  color="amber darken-2"
                  class="white--text"
                  elevation="0"
                  rounded
                  x-small
                >
                  {{ isUserSubscribed ? "Premium Pass" : "Unlock All Tests" }}
                </v-btn>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <!-- Empty State  -->
      <v-col v-if="!(courses.length > 0)" cols="12" md="7" class="text-center">
        <v-alert type="warning" dense text> No courses added yet :( </v-alert>
      </v-col>

      <!-- Search Courses -->
      <v-col v-else cols="12" md="7">
        <v-autocomplete
          :disabled="loading || superLoading"
          :items="courses"
          item-text="courseName"
          item-value="courseName"
          label="What do you want to learn ?"
          append-icon="mdi-magnify"
          no-data-text="Stay tuned for more courses :)"
          color="deep-purple"
          class="mx-4"
          @change="toCoursePage"
          clearable
          solo
          rounded
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "SubStatusAndSearch",
  props: ["superLoading", "isUserSubscribed", "userData"],

  data: () => ({
    loading: true,
    error: "",
    courses: [],
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    fetchCourses() {
      // get courses array from store
      const coursesArr = this.$store.getters.courses;

      //if not found in store
      if (coursesArr.length <= 0 || typeof coursesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getMetaDoc")
          .then((res) => {
            if (res) {
              this.courses = res.courses;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.courses = coursesArr;
        this.setLoading(false);
      }
    },

    toCoursePage(val) {
      if (!val) {
        return;
      }
      this.$router.push("/courses/" + val);
      console.log("toCoursePage called", val);
    },
  },

  mounted() {
    this.fetchCourses();
  },
};
</script>
